import {createSelector} from 'reselect'
import _ from 'lodash'
import {HOST_MB} from "../../../constants/constants";

const linksSelector = state => state.links.links

export const getWhitelabelLink = createSelector(
    linksSelector,
    links => links?.['whitelabel.get']?.uri
)

export const getWhitelabelByHOST = createSelector(
    linksSelector,
    links => links ? _.replace(links['whitelabel.belair'].uri, '{id}', HOST_MB) : {}
)

export const getUserLink = createSelector(
    linksSelector,
    links => links && links['user.get']?.uri
)

export const getUserLogoutLink = createSelector(
    linksSelector,
    links => links['user.logout'].uri
)

export const getCustomerCreateLink = createSelector(
    linksSelector,
    links => links['customer.create'].uri
)

export const getCustomerResetPasswordLink = createSelector(
    linksSelector,
    links => links?.['customer.reset_password']?.uri || null
)

export const getCustomerUpdatePasswordLink = createSelector(
    linksSelector,
    links => links['customer.update_password'].uri
)

export const getCustomerRequestCodeLink = createSelector(
    linksSelector,
    links => links['customer.request_code'].uri
)

/***** CONTRACTS *****/
export const getContractsLink = createSelector(
    linksSelector,
    links => links['contracts'].uri
)

export const getContractsGetLink = createSelector(
    linksSelector,
    links => links['contracts.get'].uri
)

export const getAccidentsByContratLink = createSelector(
    linksSelector,
    links => links['contracts.accidents'].uri
)

export const getAccidentLink = createSelector(
    linksSelector,
    links => links['contracts.accidents.get'].uri
)

export const getAccidentRepairerUpdateLink = createSelector(
    linksSelector,
    links => links['contracts.accident.repairer.update'].uri
)

export const getContractReceiptsLink = createSelector(
    linksSelector,
    links => links['contracts.receipts'].uri
)

/***** CONTRACT *****/
export const postContractFractionnementUpdateLink = createSelector(
    linksSelector,
    links => links['contract.fractionnement.update'].uri
)

export const getContractPrelevements = createSelector(
    linksSelector,
    links => links['contracts.prelevements'].uri
)

/***** TIERS *****/
export const getAccidentsTiersLink = createSelector(
    linksSelector,
    links => links['tiers.accidents'].uri
)

export const getFaqLink = createSelector(
    linksSelector,
    links => links['faq'].uri
)

export const getAvantagesLink = createSelector(
    linksSelector,
    links => links['avantages'].uri
)

/***** AMENDMENT *****/
export const amendmentChangeAddressLink = createSelector(
    linksSelector,
    links => links['amendment.change_address'].uri
)

export const amendmentChangeContactLink = createSelector(
    linksSelector,
    links => links['amendment.change_contact'].uri
)

export const amendmentChangeIbanGetLink = createSelector(
    linksSelector,
    links => links['amendment.change_iban'].uri
)

export const amendmentHivernageGetLink = createSelector(
    linksSelector,
    links => links['amendment.hivernage'].uri
)

export const amendmentFinHivernageGetLink = createSelector(
    linksSelector,
    links => links['amendment.fin_hivernage'].uri
)

/***** RECEIPTS *****/
export const receiptsLink = createSelector(
    linksSelector,
    links => links['receipts'].uri
)

export const receiptGetLink = createSelector(
    linksSelector,
    links => links['receipts.get'].uri
)

export const receiptsPaidLink = createSelector(
    linksSelector,
    links => links['receipts.paid'].uri
)

/***** ARTICLES *****/
export const getArticlesLink = createSelector(
    linksSelector,
    links => links['articles'].uri
)

/***** EMAIL *****/
export const getEmailUpdateCommunicationPreferencesLink = createSelector(
    linksSelector,
    links => links['email.update_communication_preferences'].uri
)
export const getStatutDematLink = createSelector(
    linksSelector,
    links => links['user.updateDematPreferences'].uri
)

/***** REQUEST *****/
export const getRequestDocumentLink = createSelector(
    linksSelector,
    links => links['query.document'].uri
)

export const getRequestRecontactLink = createSelector(
    linksSelector,
    links => links['query.task.recontact'].uri
)

export const getContactLink = createSelector(
    linksSelector,
    links => links['contact'].uri
)

/***** DOCUMENTS *****/
export const getDocumentsNeededAccidentGetLink = createSelector(
    linksSelector,
    links => links['documents.needed.accident.get'].uri
)

export const casIdaLink = createSelector(
    linksSelector,
    links => links['contracts.accident.ida'].uri
)

export const getDecCirconstanciee = createSelector(
    linksSelector,
    links => links['contracts.declarationcirconstanciee'].uri
)

/**** Parrainages ***/
export const postParrainagesLink = createSelector(
    linksSelector,
    links => links['tiers.filleul.post'].uri
)


export const getParrainagesLink = createSelector(
    linksSelector,
    links => links['tiers.filleuls'].uri
)

export const changeBirthInfosTiersLink = createSelector(
    linksSelector,
    links => links['tiers.birth.update'].uri
)

/**** Tiers Payeur ***/
export const updateThirdPartyPayerLink = createSelector(
    linksSelector,
    links => links['contracts.third_party_payer.update'].uri
)

export const deleteThirdPartyPayerLink = createSelector(
    linksSelector,
    links => links['contracts.third_party_payer.delete'].uri
)

/**** Bénéficiaires Effectifs ***/

export const terminateGetLink = createSelector(
    linksSelector,
    links => links['contracts.resiliation.post'].uri
)


/** Flotte **/

export const getFleetLink = createSelector(
    linksSelector,
    links => links['fleet'].uri
)

export const getFleetGetLink = createSelector(
    linksSelector,
    links => links['fleet.get'].uri
)



export const getFleetVehiclesDeleteLink = createSelector(
    linksSelector,
    links => links['fleet.vehicles.delete'].uri
)

export const confirmationResilHamonGetLink = createSelector(
    linksSelector,
    links => links['contracts.hamon.update_resil'].uri
)